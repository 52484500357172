<template>
    <div>    
        <v-divider></v-divider>  
        <mbs-page-tab 
            :items="FULL_LINKS"/>   
        <v-divider></v-divider>  
        <v-divider></v-divider>  
        <v-divider></v-divider>  
        <v-container grid-list-xl> 
            <v-layout class="mt-1" align-center="" justify-center="">
                <v-col sm="12" cols="12"> 
                    <v-card :min-height="vsh/2" >   
                        <mbs-item-manager  v-model="input_table"
                            :table_title="'Manage Company Users'"
                            :table_title_icon="'mdi-account-multiple'"
                            :add_vertical_lines="true"
                            :row_click="true"
                            :show_select="false"
                            :height="null"
                            :fixed_header="false"
                            add_item_title="CREATE USER"
                            :add_item_action="{
                                action_do:'Create',
                                action_doing:'Creating'
                            }"
                            :table_tabs="TABLE_TABS"
                            :top_label="false"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM"
                            :field_items="ADDING_ITEM?ADDING_ITEM.fields:DATA.FIELDS.add_user" 
                            :header_items="HEADER_ITEMS" 
                            :data_items="CompanyUsersData"  
                            :is_joined="true"  
                            :path_item="PATH_ITEM"  
                            :select_items="DataSource"   

                            :table_actions="TableActions" 
                            @ON_ITEM_SELECTED="selected_items=$event"
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                            @ADDED="ADDED"
                            @EDITED="EDITED"
                            @DELETED="DELETED"
                        />  
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  


        <!-- //DIALOGS -->
        <div> 
            <!-- add user -->
            <div v-if="dialog_add_user">
                <v-dialog v-model="dialog_add_user" persistent max-width="800px"> 
                    <v-card>
                        <v-card-title class="primary white--text" dark>
                            <span class="headline">ADD USER</span>
                            <v-spacer></v-spacer> 
                            <v-btn icon dark @click="dialog_add_user=false"><v-icon>close</v-icon></v-btn>
                        </v-card-title>
                        <v-card-text> 
                            <v-container grid-list-lg>
                                <br>
                                <form id="updateUserForm" @submit.prevent="SUBMIT_USER()">
                                    <mbs-input-fields v-if="true"
                                        v-model="input"
                                        :field_items="DATA.FIELDS.add_user"
                                        :select_items="DataSource"
                                        /> 
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" text="" @click="dialog_add_user=false">CANCEL</v-btn>
                                        <v-btn color="primary" :loading="inputLoading" dark="" type="submit">Add User</v-btn>
                                    </v-card-actions>
                                </form> 
                            </v-container>
                            <small>Click Update button to save changes</small>
                        </v-card-text> 
                    </v-card>
                </v-dialog>  
            </div>
            <!-- edit -->
            <div v-if="dialog_edit_user">
                <v-dialog v-model="dialog_edit_user" persistent max-width="800px"> 
                    <v-card>
                        <v-card-title class="primary white--text" dark>
                            <span class="headline">EDIT USER ACCOUNT</span>
                            <v-spacer></v-spacer> 
                            <v-btn icon dark @click="dialog_edit_user=false"><v-icon>close</v-icon></v-btn>
                        </v-card-title>
                        <v-card-text> 
                            <v-container grid-list-lg>
                                <br>
                                <form id="updateUserForm" @submit.prevent="UPDATE_USER()">
                                    <mbs-input-fields v-if="true"
                                        v-model="input"
                                        :field_items="DATA.FIELDS.add_user"
                                        :update_item="true"
                                        :select_items="DataSource"
                                        /> 
                                    <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" text="" @click="dialog_edit_user=false">CANCEL</v-btn>
                                    <v-btn color="primary" :loading="input.loading" dark="" type="submit">Update User</v-btn>
                                    </v-card-actions>
                                </form> 
                            </v-container>
                            <small>Click Update button to save changes</small>
                        </v-card-text> 
                    </v-card>
                </v-dialog>  
            </div>
        </div> 

        <div v-if="ADDING_ITEM">
            <mbs-item-manager 
                :show="false" 
                :add_item_action="ADDING_ITEM.add_item_action"
                :edit_item_action="ADDING_ITEM.edit_item_action"
                :name_items="ADDING_ITEM.names"
                :name_item="ADDING_ITEM.name"
                :value_items="ADDING_ITEM.values"
                :value_item="ADDING_ITEM.value"
                :field_items="ADDING_ITEM.fields"   
                :data_items="ItemsData"  
                :is_joined="true" 
                :path_item="ADDING_ITEM.path"  
                :select_items="DataSource"  
                @ADDED="ADDED" 
            /> 
        </div> 
    </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    import {mapState,mapGetters } from "vuex" 
    let PAGE_NAME = DATA.ITEMS.USERS.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,
                dialog_scanned_item:false,
                SCANNED_ITEM:null,
                input_table:{show_select:false},
                selected_items:null,

                NAME_ITEMS:DATA.ITEMS.USERS.names,
                NAME_ITEM:DATA.ITEMS.USERS.name,
                VALUE_ITEMS:DATA.ITEMS.USERS.values,
                VALUE_ITEM:DATA.ITEMS.USERS.value,  
                DATA:DATA, 
                ADDING_ITEM:null,
                dialog_edit_user:false,
                dialog_add_user:false,
                input:{},
            }
        },
        created() {
            try { 
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES); 
                this.MBS.events.$on('ON_QR_SCAN', this.ON_QR_SCAN);    
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                // console.log(this.SelectedCompany);
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                }) 
                this.FEEDBACKS(1000)  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
            /////////
            selectedCompany(){  
                return this.$store.getters.getSelectedCompany
            },   
            FULL_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("security",2,true) 
                return thisLinks
            },
            PATH_ITEM(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.company_users,true) 
                return path
            },  
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Users: state=> state.users[(DATA.ITEMS.USERS.values).toUpperCase()],
                // JoinedUsers: state=> state.join[(DATA.ITEMS.USERS.values).toUpperCase()], 
                CompanyUsers: state=> state.items[(DATA.ITEMS.COMPANY_USERS.values).toUpperCase()],
                JoinedCompanyUsers: state=> state.join[(DATA.ITEMS.COMPANY_USERS.values).toUpperCase()], 
                CompanyUserGroups: state=> state.items[(DATA.ITEMS.COMPANY_USER_GROUPS.values).toUpperCase()], 
                JoinedCompanyUserGroups: state=> state.join[(DATA.ITEMS.COMPANY_USER_GROUPS.values).toUpperCase()],  
                Cards: state=> state.items[(DATA.ITEMS.CARDS.values).toUpperCase()],
                JoinedCards: state=> state.join[(DATA.ITEMS.CARDS.values).toUpperCase()],  
            }),
            JoinedUsers(){
                let users = this.Users
                let join = this.MBS.actions.JOIN_USERS(users)
                return join
            }, 
            JoinedCompanyUsers(){
                let users = this.CompanyUsers 
                let join = this.MBS.actions.JOIN_USERS(users) 
                return join
            }, 
            CompanyUsersData(){ 

                console.log(this.CompanyUsers,'....u,');
                console.log(this.JoinedCompanyUsers,'....j,');
                return this.JoinedCompanyUsers?this.JoinedCompanyUsers:this.CompanyUsers
            }, 
            CompanyUserGroupsData(){ 
                return this.JoinedCompanyUserGroups?this.JoinedCompanyUserGroups:this.CompanyUserGroups
            }, 
            CardsData(){ 
                return this.JoinedCards?this.JoinedCards:this.Cards
            },  
            
            TableActions(){
                let selected_items = this.selected_items
                let input_table = this.input_table
                let show_select = input_table?input_table.show_select:false
                let main_action = [
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},   
                    {type:"action",btn:true,icon:'mdi-account-plus',action:"add_action_item",color:"secondary", outlined:false,  text:"Add User"},    
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},  
                ] 
                return  main_action
            },
            TABLE_TABS(){
                return [
                    {
                        name:"All Items",show_if:(this_,item)=>{ 
                            let stock = this.toNumber(item["number_quantity"])>0?true:false
                            return true
                        },
                        indicator:{
                            color:'primary',
                            value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}
                        }
                    }}, 
                    {
                        name:"Stock",show_if:(this_,item)=>{ 
                            let stock = this.toNumber(item["number_quantity"])>0?true:false
                            return stock
                        },
                        indicator:{
                            color:'primary',
                            value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}
                        }
                    }},  
                    {
                        name:"Out of Stock",show_if:(this_,item)=>{ 
                            let stock = this.toNumber(item["number_quantity"])>0?true:false
                            return  !stock
                        },
                        indicator:{
                        color:'primary',
                        value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}}
                    }},  
                ]
            },
            FILTERS(){ 
                return [ 
                    {
                        type:"autocomplete",value:"department_code",prepend_inner_icon:'mdi-format-list-group',clearable:true,filled:false,multiple:true
                        ,chips:true,deletable_chips:true,label:"Department",
                        select:{
                            name:'ItemDepartments',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"category_code",prepend_inner_icon:'mdi-shape-plus',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Category",
                        select:{
                            name:'ItemCategories',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    },   
                    {
                        type:"autocomplete",value:"manufacturer_code",prepend_inner_icon:'mdi-factory',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Manufacturer",
                        select:{
                            name:'Manufacturers',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    },   
                    {
                        type:"autocomplete",value:"item_code",search_value:"code",barcode:true,prepend_inner_icon:'mdi-barcode',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Barcode",
                        select:{
                            name:'Items',
                            value:'code',
                            text:'code'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"key",barcode:false,prepend_inner_icon:'mdi-tag-text',clearable:true,filled:false,
                        multiple:true,chips:true,deletable_chips:true,label:"Item Name",
                        select:{
                            name:'Items',
                            value:'key',
                            text:'name'
                        }, 
                        required:false,sm:12,md:12,step_name:"Name"
                    }, 
                    
                        
                ]
            }, 
            HEADER_ITEMS(){ 
                let tiers = this.AllPricingTiers
                let headers = [
                    {id:1,name:"General",title:true},
                    {id:0,name:"image",show:true},
                    {id:0,name:"No.",value:'no',show:false},  
                    {id:0,name:"name",value:'name',show:true}, 
                    {id:0,name:"surname",value:'surname',show:false}, 
                    {id:0,name:"names",value:'names',show:false}, 
                    {id:0,name:"Phone",value:'phone_number',show:true},   
                    {id:0,name:"User Group",value:'company_user_group_name',show:true},      
                    // {id:0,name:"Card",value:'card_code',show:true},      
                    {id:0,name:"on",value:'online',show:true},   
                    {id:0,name:"logged",value:'logged',show:true},   
                    {id:0,name:"status",value:'active_status',show:false},   
                    {id:0,name:"create",value:'created_at_',show:false},   
                    {id:0,name:"update",value:'updated_at_',show:false}, 
                    {id:0,name:"user",show:false}, 
                    {id:0,name:"action",align:"center",show:true,item:[  
                        {name:"Edit "+this.NAME_ITEM,action:"edit_action_item",icon:"mdi-pencil"},
                        {name:"Remove "+this.NAME_ITEM,action:"delete_action_item",icon:"mdi-delete"},
                    ]}, 
                ] 
                return headers 
            },  
            DataSource(){ 
                let Users = this.JoinedUsers
                let UserGroups = this.CompanyUserGroupsData 
                let Cards = this.CardsData 
                return  {   
                    Users: Users, 
                    UserGroups: UserGroups, 
                    Cards: Cards, 
                }
            }, 
        },
        methods: {  
            TABLE_ACTION(action,item){
                try { 
                    let route = this.route
                    let fullPath = route?route.fullPath:null
                     console.log(action,'action');
                    if (action == "add_action_item") {
                        this.ADD_USER()
                    }else if (action == "refresh") {
                        let mbs_text = this.MBS.text.item_action("Company Users","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=RELOAD-COMPANY-USERS", 
                            } 
                        })
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ITEM_ACTION(action,item,option){
                try {     
                    console.log(action,'***************');
                    if (action == "on_row_clicked") {
                         
                    }else if (action == "edit_action_item") {
                        this.EDIT_COMPANY_USER(item)
                    } else if (action == "delete_action_item") {
                        this.DELETE_COMPANY_USER(item)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CANCEL_SELECTED_ITEMS(items){
                try {     
                    console.log('oo...............');
                    this.MBS.events.$emit('CANCEL_SELECTED_ITEMS')
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CANCEL_SELECTED_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            CREATE_ITEM(input,barcode){
                try {     
                    this.ADDING_ITEM = {...DATA.ITEMS.ITEMS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.items
                    this.ADDING_ITEM.add_item_action =  {
                        action_do:'add',
                        action_doing:'Creating'
                    }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEMS.name,
                            action:"add_item",
                            input:{
                                name:input,
                                code:barcode,
                            }
                        })  
                    }, 50);
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            EDIT_ITEM(item){
                try {      
                    this.ADDING_ITEM = {...DATA.ITEMS.ITEMS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.items
                    this.ADDING_ITEM.edit_item_action =  {
                        action_do:'Edit',
                        action_doing:'Editing'
                    }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEMS.name,
                            action:"edit_item",
                            item:item,
                            // input:{
                            //     name:input,
                            //     code:barcode,
                            // }
                        })  
                    }, 50);
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ITEM_ADD_KITS(item){
                try {    
                    this.ADDING_ITEM = {...DATA.ITEMS.ITEM_KITS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.Add_items_kit
                    this.ADDING_ITEM.add_item_action =  {
                        action_do:'Create',
                        action_doing:'Creating'
                    }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEM_KITS.name,
                            action:"edit_item",
                            item:item
                        })  
                    }, 50); 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ADD_KITS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            EDIT_ITEM_PRICE(item){
                try {    
                    this.ADDING_ITEM = {...DATA.ITEMS.ITEMS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.edit_item_price
                    this.ADDING_ITEM.add_item_action =  {
                        action_do:'Create',
                        action_doing:'Creating'
                    }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEMS.name,
                            action:"edit_item",
                            item:item
                        })  
                    }, 50);
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_ITEM_PRICE',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            EDIT_ITEM_DEPARTMENT_AND_CATEGORY(item){
                try {    
                    this.ADDING_ITEM = {...DATA.ITEMS.ITEMS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.edit_items_department_and_category
                    this.ADDING_ITEM.add_item_action =  {
                        action_do:'Create',
                        action_doing:'Creating'
                    }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEMS.name,
                            action:"edit_item",
                            item:item
                        })  
                    }, 50); 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_ITEM_DEPARTMENT_AND_CATEGORY',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ADDED(res){
                try { 
                    this.RELOAD_ITEMS()
                    //a
                    console.log(res,'--------------added');
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADDED',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            EDITED(res){
                try {
                    console.log(res,'--------------edited');
                    // this.RELOAD_ITEMS()
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADDED',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CREATE_SCANNED_ITEM(code){
                try {
                    this.dialog_scanned_item = false
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEMS.name,
                            action:"add_item",
                            input:{code:code}
                        })  
                    }, 50);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_SCANNED_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            EDIT_SCANNED_ITEM(item){
                try {
                    this.dialog_scanned_item = false
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEMS.name,
                            action:"edit_item",
                            item:item
                        })  
                    }, 50);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_SCANNED_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            DELETED(res){
                try {
                    this.RELOAD_ITEMS()
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DELETED',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            RELOAD_ITEMS(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Refresh..."
                    })
                    this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.COMPANY_USERS.values,
                        on:true, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }).then(res=>{
                        console.log(res,'res.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.COMPANY_USERS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{
                        console.log(error,'error.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.COMPANY_USERS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    })
                } catch (error) {
                    
                } 
            },
            RELOAD_PAUSE_ITEMS(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"off_refs..."
                    })
                    this.$store.dispatch('off_refs',{
                        name:DATA.ITEMS.ITEMS.values,
                        on:true, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }).then(res=>{
                        console.log(res,'off_refs res.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.ITEMS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{
                        console.log(error,'error.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.ITEMS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    })
                } catch (error) {
                    
                }

            },
            SELECT_SCANNED_ITEM(item_key, item_code){
                try {  
                    let items = this.AllItems 
                    this.SCANNED_ITEM = {code:item_code,key:item_key}
                    if (items) { 
                        let item_selected = items.find(item=>{
                            return  item_key?item.key == item_key:item.code == item_code
                        })
                        if (!item_selected) {
                            this.SCANNED_ITEM.item_code_not_found = true
                        } else {
                            this.SCANNED_ITEM = item_selected
                        }
                        this.dialog_scanned_item = true
                        console.log(item_selected,"item_selected.........."); 
                    }
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_SCANNED_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },

            ADD_USER(){
                try {    
                    this.input = {}
                    this.inputLoading = false
                    this.dialog_add_user = true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_USER',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            SUBMIT_USER(){
                try {    
                    let p = true
                    if (!p) {
                        let mbs_text = this.MBS.text.edit_item_permission_denied(this.NAME_ITEMS)
                       this.MBS.actions.dialog({
                           show:true,
                           fixed:true,
                           title:mbs_text.title,
                           text:mbs_text.text,
                           btnYes:mbs_text.btnYes 
                       }) 
                    } else { 
                        let input = this.input
                        let user = input?input.user:null
                        let card_key = input?input.card_key:null
                        let key = user?user.key:null
                        let phone_number = user?user.phone_number:null
                        let company_user_group_code = input?input.company_user_group_code:null
                        let selectedCompany = this.selectedCompany
                        let company_key = selectedCompany?selectedCompany.key:null
                        let user_details = {
                            phone_number:phone_number,
                            key:key,
                            uid:key,
                            card_key:card_key,
                            company_user_group_code:company_user_group_code,
                            company_key:company_key
                        }
                        console.log(input,'......');
                        console.log(user_details,'user_details......');
                        let mbs_text = this.MBS.text.item_action(this.NAME_ITEM,"Add")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=ADD-USER",
                                data:user_details
                            }
                        })  
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_USER',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            EDIT_COMPANY_USER(user){
                try {    
                    let p = true
                    if (!p) {
                        let mbs_text = this.MBS.text.edit_item_permission_denied(this.NAME_ITEMS)
                       this.MBS.actions.dialog({
                           show:true,
                           fixed:true,
                           title:mbs_text.title,
                           text:mbs_text.text,
                           btnYes:mbs_text.btnYes 
                       }) 
                    } else {  
                        this.inputCode = null
                        this.input = {}  
                        this.input.company_user_group_code = user.company_user_group?user.company_user_group.code:'' 
                        this.input.key = user.key  
                        this.input.company_key = user.company_key  
                        this.input.phone_number = user.phone_number  
                        this.dialog_edit_user= true  
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_COMPANY_USER',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            DELETE_COMPANY_USER(user){
                try {    
                    let selectedCompany = this.selectedCompany
                    let company_key = selectedCompany?selectedCompany.key:null
                    let p = true
                    if (!p) {
                        let mbs_text = this.MBS.text.edit_item_permission_denied(this.NAME_ITEMS)
                       this.MBS.actions.dialog({
                           show:true,
                           fixed:true,
                           title:mbs_text.title,
                           text:mbs_text.text,
                           btnYes:mbs_text.btnYes 
                       }) 
                    } else {  
                        console.log(user,'user............');
                        let key = user.key?user.key:user.uid  
                        if (!key) {
                            let mbs_text = this.MBS.text.error_0
                            this.MBS.actions.dialog({
                                show:true,
                                title:mbs_text.title,
                                text:mbs_text.text,
                                btnYes:mbs_text.btnYes, 
                            })  
                        } else { 
                            let mbs_text = this.MBS.text.delete_item('Company User')
                            this.MBS.actions.dialogConfirmPassword({
                                show:true,
                                fixed:true,
                                icon:"mdi-account-off",
                                title:mbs_text.title,
                                text:mbs_text.text,
                                btnYes:mbs_text.btnYes,
                                btnNo:mbs_text.btnNo,
                                action:{
                                    code:PAGE_NAME+"=DELETE-COMPANY-USER",
                                    all_users:true,
                                    phone_number_start:true,
                                    get_access:{
                                        permission_type:"action",
                                        permission_to:"delete",
                                        action_name:DATA.ITEMS.COMPANY_USERS.names 
                                    },
                                    data:{
                                        ...user,
                                        company_key:company_key
                                    }
                                }
                            })  
                        }
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DELETE_COMPANY_USER',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            ADMIN_RIGHT(user){
                try {
                    let admin = user.admin
                    let title = ''
                    let text = ''
                    let btnYes = ''
                    let btnNo = 'Cancel'
                    if (admin) {
                        title = "REMOVE ADMIN RIGHT"
                        text = "Are you sure you want to remove admin right for the user ("+user.phone_number+")?"
                        btnYes = 'REMOVE'
                    } else {
                        title = "ADD ADMIN RIGHT"
                        text = "Are you sure you want to add admin right for the user ("+user.phone_number+")?"
                        btnYes = 'ADD'
                    }
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:title,
                        text:text,
                        btnNo:btnNo,
                        btnYes:btnYes

                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ADMIN',
                        page:PAGE_NAME, 
                    }) 
                }
            },  
            UPDATE_USER(){  
                try {   
                    let key = this.input.key 
                    let company_user_group_code = this.input.company_user_group_code 
                    let company_key = this.input.company_key 
                    if (!company_user_group_code) {
                        let mbs_text = this.MBS.text.add_item_error_not_selected('User Group')
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })   
                    }else { 
                        let data = {
                            key:key, 
                            company_user_group_code:company_user_group_code, 
                            company_key:company_key, 
                        }   
                        let mbs_text = this.MBS.text.update_item('user')
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=UPDATE-COMPANY-USER",
                                data:data
                            }
                        })   
                    }
                } catch (error) { 
                    this.MBS.actions.error({
                        error:error,
                        from:'UPDATE_USER',
                        page:PAGE_NAME, 
                    })
                }
            }, 
            async DELETE_ALL_ITEMS(){
                try {
                    let items = this.ItemsData
                    let size = this.MBS.actions.SIZE(items)
                    let progress = 0

                     
                    if(!items){
                        console.log(items,size,"no items.......");
                    } 
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Deleting ("+size+"/"+progress+") ..."
                    })

                    let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)

                    
                    for (const item of items) {
                        let res = await this.$store.dispatch('fi_delete',{
                            action:DATA.ITEMS.ITEMS.values+"__", 
                            path:path+item.key, 
                        }) 
                        progress = progress+1
                        this.MBS.actions.progressDialog({ 
                            show:true,
                            text:"Deleting ("+size+"/"+progress+") ..."
                        })
                        console.log(res,'res....');
                    }
                    this.MBS.actions.progressDialog()
                } catch (error) {
                    
                }

            },
            
            //---------------[MAIN FUNCTIONS]------------ 
            ON_QR_SCAN(code){
                try {
                    if (code) { 
                        //check cashup
                        // if (!this.LocalCashup) { 
                        //     this.MBS.actions.dialog({
                        //         show:true,
                        //         fixed:true,
                        //         title:"OPEN REGISTER FIRST",
                        //         text:"You must open the Register first before you can scan.",
                        //         btnYes:"OK"
                        //     })
                        //     return
                        // }
                        
                        let size = this.MBS.actions.SIZE(code)
                        if (size<3) { 

                        }else{
                            this.SELECT_SCANNED_ITEM(null,code) 
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_QR_SCAN',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=RELOAD-COMPANY-USERS') {
                            if (action.YES) {
                                this.RELOAD_ITEMS()
                            } else {
                                
                            }
                        }
                        if (action.code ===PAGE_NAME+'=ADD-USER') {
                            if (action.YES) {
                                setTimeout(() => {  
                                    this.inputLoading = true  
                                    this.$store.dispatch("fi_update",{ 
                                        action:this.VALUE_ITEM,
                                        path:this.PATH_ITEM+action.data.key,
                                        data:action.data,
                                        us:this.us
                                    }).then(res=>{
                                        return this.$store.dispatch("fi_update",{ 
                                            action:this.VALUE_ITEM,
                                            path:DATA.PATHS.users+action.data.key+"/companies/"+action.data.company_key,
                                            data:{
                                                company_user_group_code: action.data.company_user_group_code
                                            },
                                            us:this.us
                                        })
                                    }).then(res=>{
                                        this.inputLoading = false  
                                        this.dialog_add_user = false  
                                        console.log(res,'res....');
                                    }).catch(error=>{
                                        console.log(error,'errr.....');
                                        this.inputLoading = false  
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            } 
                        } 
                        if (action.code ===PAGE_NAME+'=UPDATE-COMPANY-USER') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.inputLoading = true  
                                    this.$store.dispatch("fi_update",{ 
                                        action:this.VALUE_ITEM,
                                        path:this.PATH_ITEM+action.data.key,
                                        data:{
                                            company_user_group_code: action.data.company_user_group_code
                                        },
                                        us:this.us
                                    }).then(res=>{
                                        return this.$store.dispatch("fi_update",{ 
                                            action:this.VALUE_ITEM,
                                            path:"/USERS/"+action.data.key+"/companies/"+action.data.company_key,
                                            data:{
                                                company_user_group_code: action.data.company_user_group_code
                                            },
                                            us:this.us
                                        })
                                    }).then(res=>{
                                        this.inputLoading = false  
                                        this.dialog_edit_user = false  
                                        console.log(res,'res....');
                                    }).catch(error=>{
                                        console.log(error,'errr.....');
                                        this.inputLoading = false  
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            } 
                        }     
                        if (action.code ===PAGE_NAME+'=DELETE-COMPANY-USER') { 
                            if (action.YES) {
                                setTimeout(async() => { 
                                    this.input.check = false
                                    this.input.loading = true 
                                    const uid = action.data.key?action.data.key:action.data.uid
                                    const company_key = action.data.company_key 
                                    this.input.loading = true 

                                    this.$store.dispatch("fi_delete",{ 
                                        action:this.VALUE_ITEM,
                                        path:this.PATH_ITEM+uid  
                                    }).then(res=>{
                                        return this.$store.dispatch("fi_delete",{ 
                                            action:this.VALUE_ITEM,
                                            path:DATA.PATHS.users+uid+"/companies/"+company_key
                                        })
                                    }).then(res=>{
                                        this.inputLoading = false  
                                        this.dialog_add_user = false  
                                        console.log(res,'res....');
                                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.COMPANY_USERS.values,"Deleting")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    }).catch(error=>{
                                        console.log(error,'err.....');
                                        this.inputLoading = false 
                                        let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.COMPANY_USERS.values,"Deleting")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        }) 
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }
                        }   
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            if (!gettingItems) {
                                this.PAGE_LOAD =true
                            }  
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        beforeDestroy(){
            try {
                this.MBS.events.$off('ON_QR_SCAN', this.ON_QR_SCAN);  
                this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'beforeDestroy',
                    page:PAGE_NAME, 
                })
            }
        },
        watch: {  
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            }, 
            selected_items(value){
                console.log(value,'selected_items.................');
            }, 
        },
    }
</script>
 
